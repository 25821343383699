.navbar {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    background-color: #07b;
    color: #fff;
}
.navbar_element--left {
    font-size: 1.5rem;
}
.navbar_element--right {
    display: flex;
    gap: 0 10px;
    align-items: center;
}
.navbar__button {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}
.container--page {
    margin: 30px 30px 0 30px;
}