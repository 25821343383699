.container--split {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 30px;
}

.container--split > div {
    width: calc(50% - 30px);
}

.container--space-between {
    display: flex;
    justify-content: space-between;
}