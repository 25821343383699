.prices-list {
    width: 100%;
}

.prices-list td, .prices-list th {
    padding: 5px;
}

.prices-list tr:nth-child(2n) {
    background-color: #e6e6e6;
}

.prices-list > tr > td:first-child, .prices-list > tr > th:first-child {
    width: 1%;
    white-space: nowrap;
}

.prices-list input {
    width: 100%;
    background-color: transparent;
}

.prices-list__name {
    width: 200px;
    word-break: break-all;
}