.container--logs {
    padding: 5px;
    width: 100%;
    height: 800px;
    overflow: scroll;
    background-color: #f2f2f2;
    font-size: 0.8em;
}

.log {

}

.log:nth-child(2n) {
    background-color: #e6e6e6;
}

.log__summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 0;
}

.log__summary > div:first-child {
    display: flex;
    gap: 5px;
    align-items: center;
    line-height: 17px;
}

.log__unfold {
    margin-left: 40px;
}