.container--upcoming-tasks {
    width: 100%;
}

.upcoming-tasks__table {
    width: 100%;
    text-align: left;
}

.upcoming-tasks__table tr:nth-child(2n) {
    background-color: #e6e6e6;
}