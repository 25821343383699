.modal {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
}

.container--modal {
    background-color: #fefefe;
    margin: 40vh auto;
    padding: 20px 20px 35px 20px;
    border: 1px solid #888;
    width: 100%;
    max-width: 600px;
}

.modal__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}