@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: "Roboto", sans-serif;
    font-weight: lighter;
    padding-bottom: 50px;
}

.container--vertical {
    display: flex;
    flex-direction: column;
    gap: 20px 0;
}

.container--horizontal {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}

.small-text {
    font-size: 0.7rem;
}

.text-align-center {
    text-align: center;
}